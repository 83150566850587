import './styles.scss';
import React, {memo} from 'react';
import {HomeOverviewComponent} from "../../types/ApiComponent";
import {WITH_API_BASE} from "../../configs/appConfigs";
import { Link } from 'gatsby';

type Props = {
    fullLink: string,
    data: HomeOverviewComponent
}

const homeOverviewBusiness = ({data, fullLink}: Props) => {
    return (
        <div className="container" id={'tools'}>
            <div className="homeOverviewBusiness">
                <div className="homeOverviewBusinessBox p-small">
                    <div className="homeOverviewBusinessLeft pl0">
                        <div className="homeOverviewBusinessTitle ttu">
                            AI-powered app for smart personal and business development
                        </div>
                        <div className="homeOverviewBusinessDesc">Improve your performance with interactive soft skills training courses & productivity tools in one app</div>
                        <Link to={fullLink} className="homeOverviewBusinessBtn">Get started</Link>
                    </div>
                    <div className="homeOverviewBusinessImage">
                        <img src={WITH_API_BASE(data.BgImage?.data?.attributes?.url || '')} width={684} height={511} alt=""/>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default memo(homeOverviewBusiness);
